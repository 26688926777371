html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.empty-option {
  font-size: 0.9em;
  font-style: italic;
}

li {
  margin: 4px 0;
}

.rdrCalendarWrapper {
  border-radius: 8px !important;
}

.rdrDateDisplayWrapper {
  border-radius: 8px 8px 0 0 !important;
}

.rdrMonth {
  border-radius: 0 0 8px 8px !important;
}

.rdrMonthPicker option, .rdrYearPicker option, .rdrMonthPicker select, .rdrYearPicker select, .rdrDateDisplayItemActive input, .rdrDateDisplayItem input {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.rdrMonthPicker.options li, .rdrYearPicker.options li {
  margin: 0 0;
  padding: 0 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c8d3dc;
  border-radius: 20px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b2bdc6;
}

/* Firefox old*/
@-moz-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0.5;
  }
  100% {
      opacity:1;
  }
} 

@-webkit-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0.5;
  }
  100% {
      opacity:1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0.5;
  }
  100% {
      opacity:1;
  }
} 
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0.5;
  }
  100% {
      opacity:1;
  }
}
