html {
  scroll-behavior: smooth;
}
.content {
  margin: 1em 0;
}

.content .content-block {
  padding: 2em;
  box-shadow: 0px 4px 46px 10px rgba(0, 0, 0, 0.1);
}

.MuiFormControl-root {
  width: 100%;
}

.MuiSelect-root, .MuiSelect-select {
  width: 100%;
}

.MuiSvgIcon-root {
  margin: 0 auto;
}

@media print {
  .noprint {
    display: none !important;
  }
  .noprint-hide {
    visibility: hidden !important;
  }
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 !important;
}

.MuiListItemIcon-root {
  display: flex;
  justify-content: center;
}

/* DRAWER */

.activeLinkButton::before {
  cursor: default;
  position: absolute;
  content: "";
  background-color: transparent;
  right: 0;
  top: -100px;
  height: 100px;
  width: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0 50px 0 0 #E5ECF2;
  z-index: -2;
}

.activeLinkButton::after {
  cursor: default;
  position: absolute;
  content: "";
  background-color: transparent;
  right: 0;
  bottom: -100px;
  height: 100px;
  width: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0 -50px 0 0 #E5ECF2;
  z-index: -2;
}